
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

@mixin media-phone() {
  @include media-breakpoint-down(xs) {
    @content;
  }
}

@mixin user-select-none {
  -webkit-user-select: none;
  user-select: none;
}
