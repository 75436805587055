
@import 'lato';

:root {

  --navigation-width: 80px;
  --now-playing-height: 100px;
  --playlist-panel-width: 290px;
  --library-item-size: 172px;
  --library-item-space: 24px;

  --faded-opacity: 0.6;
  --disabled-opacity: 0.3;
  --ghosted-opacity: 0.15;

  --text-color: white;
  --faded-text-color: rgba(255, 255, 255, var(--faded-opacity));
  --disabled-text-color: rgba(255, 255, 255, var(--disabled-opacity));
  --ghosted-text-color: rgba(255, 255, 255, var(--ghosted-opacity));

  --highlight-color: #3498da;
  --danger-color: #e74c3c;
  --success-color: green;

  --dark-bg-rgb: 14,21,33;

  --content-padding: 32px;
  --content-header-height: 64px;

  --app-background: linear-gradient(to top, #141e30, #243b55);

  --video-thumbnail-ratio: 4/3;
  --video-cover-ratio: 3/2;

  --popover-height: 400px;

}

@import 'mixins';
@include media-phone() {
  :root {
    --navigation-width: 60px;
    --content-padding: 16px;
    --now-playing-height: 80px;
    --library-item-size: 128px;
    --library-item-space: 16px;
  }
}

html, body, div, p {
  color: var(--text-color);
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;

}

*::-webkit-scrollbar {
  display: none;
}

body {
  overflow: hidden;
}

span {
  vertical-align: middle;
}

a, a:focus, a:hover, a:visited {
  color: var(--highlight-color);
}

.control {

  &.enabled {
    color: var(--text-color) !important;
  }

  &.disabled {
    color: var(--disabled-text-color) !important;
  }

  i {
    cursor: pointer;
  }

}

.cursor-pointer {
  cursor: pointer;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: var(--highlight-color);
  background-color: var(--highlight-color);
}

.popover, .panel {
  @include user-select-none;
  .panel-header, .popover-header {
    i {
      cursor: pointer;
    }
    .menu {
      float: right;
      margin-left: 32px;
      i {
        margin-left: 4px;
      }
    }
  }
}

.popover {

  .popover-body {
    min-width: 140px;
    max-height: var(--popover-height);
    overflow-y: auto;
  }

  &.large {
    .popover-body {
      min-width: 280px;
    }
  }

  &.menu {
    .popover-body {
      div {
        padding: 4px;
        cursor: pointer;
        &:hover {
          color: var(--highlight-color);
        }
        &.disabled {
          color: var(--faded-text-color);
        }
      }
    }
  }

  &.no-arrow {
    .arrow {
      display: none;
    }
  }
}

.panel {

  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: scroll;
  background-color: rgba(var(--dark-bg-rgb), .5);

  &.collapsed {
    width: 0;
    visibility: hidden;
  }

  .panel-header {
    padding: 0.5rem 0.75rem;
    background-color: rgba(var(--dark-bg-rgb), .95);
    font-size: 0.9375rem;
  }

  .panel-body {
    padding: 8px;
    font-size: .9rem;
    overflow-y: scroll;
  }

}

.hover-highlight:hover {
  color: var(--highlight-color);
}

.list-picker div {
  padding: 8px;
}

.b-toast .toast-header {
  
  strong {
    font-weight: normal;
  }

  button.close {
    color: inherit;
  }

}

.tabs {

  .nav-tabs {
    border-bottom: 0px;

    .nav-item .nav-link {
      border-width: 0;
      font-weight: bold;
      text-transform: uppercase;
      color: var(--faded-text-color);
      padding: .5rem 1.5rem;
    
      &.active {
        background-color: transparent;
        color: var(--text-color);
        border-bottom: 2px solid var(--highlight-color);
      }
    }
  }

  .tab-content {
    padding: 32px;
  }

}
